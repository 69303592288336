import React from "react"
import { graphql } from 'gatsby'

import {
  Box,
  Fab,
  Typography,
} from '@mui/material';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TemplateContent from '../components/TemplateContent';

import * as helpers from "../helpers"

export default function PageTemplate(props) {
  const template = helpers.normalizeTemplateData(props.data.template.nodes[0]);

  let subTitle = null;
  if (template.id.indexOf('ht') >= 0) {
    subTitle = 'HTML Email Template';
  } else if (template.id.indexOf('pt') >= 0) {
    subTitle = 'Plain Text Email Template';
  }

  return (
    <Layout>
      <Box
        sx={{
          px: [1, 2, 2, 4],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              'xs': 'column',
              'sm': 'row',
            },
            padding: '50px 0px 60px 0px',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography
              variant='h1'
              sx={{
                fontSize: '1.4rem',
                color: 'grey',
              }}
            >
              {subTitle}
            </Typography>
            <Typography
              variant='h2'
              sx={{
                fontSize: '3rem',
                paddingBottom: '10px'
              }}
            >
              {`#${template.id}`}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '130px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Fab
              sx={{

              }}
              href={props.pageContext.lastPage}
              disabled={props.pageContext.lastPage === null}
            >
              <ChevronLeft />
            </Fab>
            <Fab
              sx={{

              }}
              href={props.pageContext.nextPage}
              disabled={props.pageContext.nextPage === null}
            >
              <ChevronRight />
            </Fab>
          </Box>
        </Box>
        <TemplateContent
          template={template}
        />
      </Box>
    </Layout>
  )
}

export const Head = ({location, data}) => {
  const template = helpers.normalizeTemplateData(data.template.nodes[0]);

  let templateType = null;
  let imagePath = null;
  if (template.id.indexOf('ht') >= 0) {
    templateType = 'HTML Email Template';
    imagePath = template.exampleDesktopImg.images.fallback.src;
  } else if (template.id.indexOf('pt') >= 0) {
    templateType = 'Plain Text Email Template';
    // image = '';
  }

  return (
    <Seo
      title={`${templateType} #${template.id} | One of 250+ Review Request Email Examples`}
      description={template.description}
      url={location.pathname ? location.pathname : null}
      imagePath={imagePath}
    />
  );
}

export const query = graphql`
  query allTemplateQuery($name: String!) {
    template: allDirectory(filter: {relativePath: {eq: $name}}) {
      nodes {
        relativePath
        childFiles {
          name
          childTemplateData {
            description
            variables {
              description
              variable
              example
            }
          }
          childPlainText {
            content
          }
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
